import "./base-page.scss";

type BasePageProps = {
  children: React.ReactNode;
};

const BasePage: React.FC<BasePageProps> = (props) => {
  return <div className="_base_page">{props.children}</div>;
};

export default BasePage;
