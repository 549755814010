import { useCallback, useEffect, useRef, useState } from "react";
import { ItemMaster } from "../../../model/pos/item-master";
import "./website-item-detail-page.scss";
import {
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiImage,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import { Link } from "react-router-dom";
import { ShoppingCartService } from "../../../services/shopping-cart-service";
import { useSelector } from "react-redux";
import {
  posStoreStateSelector,
  registerStateSelector,
} from "../../../state/slices/model-slice";
import { PointOfSaleEvent } from "../../../model/sanofi-otc/point-of-sale-event";
import SurveyModalWeb from "../../../components/survey-modal-web/survey-modal-web";
import { CostcoLogo } from "../../../assets/assets";
import SurveyPageWeb from "../../survey-web/survey-page-web";
import { QubesService } from "../../../services/qubes-service";

export type WebsiteItemDetailPageProps = {
  item: ItemMaster;
};

/**
 * This is the page that controls all actions within the POS demo track and
 * renders all the appropriate child components
 * @returns {JSX.Element}
 */
const WebsiteItemDetailPage = ({
  item,
}: WebsiteItemDetailPageProps): JSX.Element => {
  const [itemCount, setItemCount] = useState(1);

  // The register we are running on
  const register = useSelector(registerStateSelector);
  // The store the register is in
  const store = useSelector(posStoreStateSelector);

  // The state of the code entry modal
  const [isCodeModalOpen, setIsCodeModalOpen] = useState<boolean>(false);

  // The state of the survey scan modal
  const [isSurveyModalOpen, setIsSurveyModalOpen] = useState<boolean>(false);

  const validSurveyCode = useRef<string>();
  const validQrUid = useRef<string>();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const updateItemCount = useCallback(
    (newValue: string) => {
      const parsedInt = parseInt(newValue);
      if (!Number.isNaN(parsedInt)) return;
      else setItemCount(parsedInt);
    },
    [setItemCount]
  );

  const decrement = useCallback(() => {
    if (itemCount > 0) {
      setItemCount((currVal) => currVal - 1);
    }
  }, [itemCount, setItemCount]);

  const increment = useCallback(() => {
    setItemCount((currVal) => currVal + 1);
  }, [setItemCount]);

  const handleSurveyScan = useCallback(
    (posEvent?: PointOfSaleEvent) => {
      //if there isn't a selectedItem, what do we do??? There's no way to recover and deal with the modal
      if (!item) return;

      setIsCodeModalOpen(false);
      if (posEvent) {
        ShoppingCartService.get().add(item, 1);
        ShoppingCartService.get().setItemToEvent(item, posEvent);
      }
    },
    [setIsCodeModalOpen, item]
  );

  const addToCart = useCallback(() => {
    if (item.sanofiOtcSku) {
      if (validSurveyCode.current) {
      } else {
        console.debug("User has requested a product requiring HSA");
        setIsCodeModalOpen(true);
      }
    } else {
      ShoppingCartService.get().add(item, itemCount);
    }
  }, [item, itemCount]);

  const handleNewSurveyCode = useCallback(
    (newSurveyCode: string, qrUid: string) => {
      validSurveyCode.current = newSurveyCode;
      validQrUid.current = qrUid;
    },
    []
  );

  const handleSurveyClose = useCallback(
    (addToCart: boolean) => {
      setIsSurveyModalOpen(false);
      if (addToCart && validSurveyCode.current && register && store) {
        console.info(`Insert POSEvent`);
        const posEvent = new PointOfSaleEvent({
          uid: "",
          initiated: new Date().toISOString(),
          quantity: 0,
          rawCorrelationCode: validSurveyCode.current,
          correlationCodeUid: validQrUid.current,
          registerId: register?.uid,
          status: "Granted",
          store: store.uid,
          active: true,
        });
        QubesService.insertPOSEvent(posEvent).then((event) => {
          if (
            !event.success ||
            (event.numberOfRowsUpdated ?? 0) < 1 ||
            !event.keys
          ) {
            console.error(`Failed to insert the POSEvent record`);
          } else {
            posEvent.uid = event.keys?.uid;
          }
          handleSurveyScan(posEvent);
        });
      }
    },
    [handleSurveyScan, register, store]
  );

  return (
    <EuiFlexGroup
      className="website-item-detail-page"
      direction="column"
      alignItems="center"
      justifyContent="flexStart"
      gutterSize="none"
    >
      <EuiSpacer size="l" />

      {/* path */}
      <EuiFlexItem key={"item-path"} className="item-path" grow={false}>
        <EuiFlexGroup direction="row" alignItems="center" gutterSize="s">
          <Link key={"Home"} to={`/web`}>
            Home
          </Link>
          {item.websiteCategoryPath?.map((stepInPath) => {
            return (
              <EuiFlexGroup key={stepInPath} alignItems="center" gutterSize="s">
                <EuiText key={`${stepInPath}-slash`}>/</EuiText>
                <Link key={stepInPath} to={`/web`}>
                  {stepInPath}
                </Link>
              </EuiFlexGroup>
            );
          })}
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiSpacer size="l" />

      {/* detail row */}
      <EuiFlexGroup
        key={"item-main-detail-row"}
        className="item-main-detail-row"
        direction="row"
      >
        <EuiFlexItem className="item-image-wrapper" grow={true}>
          <EuiImage
            className="item-image"
            src={item.imageUrlLarge}
            alt="item image"
          />
        </EuiFlexItem>
        <EuiFlexItem className="item-details-wrapper">
          <EuiFlexGroup
            className="item-details"
            direction="column"
            justifyContent="flexStart"
            gutterSize="none"
          >
            <EuiText className="item-details--name">{item.name}</EuiText>
            <EuiSpacer size="m" />
            <EuiText size="xs">Item 269852</EuiText>
            <EuiSpacer size="s" />
            <EuiFlexItem grow={false}>
              <EuiFlexGroup
                className="item-details--price"
                direction="row"
                justifyContent="spaceBetween"
              >
                <EuiText className="item-details--price-values">
                  Your Price
                </EuiText>
                <EuiText className="item-details--price-values">
                  ${item.price}
                </EuiText>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiSpacer size="xl" />
            <EuiFlexGroup
              className="item-features"
              direction="column"
              alignItems="flexStart"
              justifyContent="flexStart"
              gutterSize="none"
            >
              <EuiText>Features:</EuiText>
              <EuiFlexItem>
                <ul>
                  {item.primaryFeatures?.map((feature) => {
                    return <li key={feature}>{feature}</li>;
                  })}
                </ul>
                {item.sanofiOtcSku ? (
                  <ul className="sanofiSku">
                    <li>**Purchase Restrictions Apply</li>
                  </ul>
                ) : null}
              </EuiFlexItem>
            </EuiFlexGroup>
            {item.sanofiOtcSku ? (
              <EuiFlexItem className="item-features-restrictions">
                <span>
                  **Purchase of this product requires a valid HSA (health survey
                  assessment) code.{" "}
                  {
                    // eslint-disable-next-line
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsSurveyModalOpen(true);
                      }}
                    >
                      Click here
                    </a>
                  }{" "}
                  for more details.
                </span>
              </EuiFlexItem>
            ) : null}
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem className="item-ordering-wrapper">
          <EuiFlexGroup className="item-ordering" direction="column">
            <EuiText>Delivery</EuiText>
            <EuiFieldText
              value={itemCount}
              onChange={(evt) => updateItemCount(evt.target.value)}
              prepend={
                !item.sanofiOtcSku ? (
                  <EuiIcon type={"minus"} onClick={decrement} />
                ) : undefined
              }
              append={
                !item.sanofiOtcSku ? (
                  <EuiIcon type={"plus"} onClick={increment} />
                ) : undefined
              }
            />
            <EuiButton onClick={addToCart}>Add to Cart</EuiButton>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      {/* further details */}
      <EuiFlexGroup
        key={"item-further-details"}
        className="item-further-details"
        direction="column"
        alignItems="baseline"
      >
        <EuiFlexItem className="section-names">
          <EuiFlexGroup direction="row" justifyContent="spaceEvenly">
            <EuiText>Product Details</EuiText>
            <EuiText>Specifications</EuiText>
            <EuiText>Shipping & Returns</EuiText>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem className="product-details" grow={false}>
          <EuiFlexGroup direction="column" gutterSize="none">
            <EuiSpacer size="l" />
            <EuiText className="section-name-title">Product Details</EuiText>
            <EuiSpacer size="m" />
            <EuiFlexItem>
              <ul>
                {item.primaryFeatures?.map((feature) => {
                  return <li key={feature}>{feature}</li>;
                })}
              </ul>
            </EuiFlexItem>
            <EuiSpacer size="s" />
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem className="specifications" grow={false}>
          <EuiFlexGroup direction="column" gutterSize="none">
            <EuiSpacer size="l" />
            <EuiText className="section-name-title">Specifications</EuiText>
            <EuiSpacer size="m" />
            <EuiFlexItem>
              <table>
                <tbody>
                  {item.specifications?.map((spec) => {
                    return (
                      <tr key={spec[1]}>
                        <td>{spec[0]}</td>
                        <td>{spec[1]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </EuiFlexItem>
            <EuiSpacer size="s" />
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem className="shipping-and-returns" grow={false}>
          <EuiFlexGroup direction="column" gutterSize="none">
            <EuiSpacer size="l" />
            <EuiText className="section-name-title">Shipping & Returns</EuiText>
            <EuiSpacer size="m" />
            <EuiFlexItem>
              <table>
                <thead>
                  <tr>
                    <td>Order by Noon</td>
                    <td>Delivered By</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Monday</td>
                    <td>Wednesday</td>
                  </tr>
                  <tr>
                    <td>Tuesday</td>
                    <td>Thursday</td>
                  </tr>
                  <tr>
                    <td>Wednesday</td>
                    <td>Friday</td>
                  </tr>
                  <tr>
                    <td>Thursday</td>
                    <td>Monday</td>
                  </tr>
                  <tr>
                    <td>Friday</td>
                    <td>Tuesday</td>
                  </tr>
                  <tr>
                    <td>Saturday</td>
                    <td>Tuesday</td>
                  </tr>
                  <tr>
                    <td>Sunday</td>
                    <td>Tuesday</td>
                  </tr>
                </tbody>
              </table>
            </EuiFlexItem>
            <EuiSpacer size="xl" />
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      {/* survey modal */}
      {isCodeModalOpen && register && store ? (
        <SurveyModalWeb
          handleSubmitSurvey={handleSurveyScan}
          onTakeSurvey={() => {
            setIsCodeModalOpen(false);
            setIsSurveyModalOpen(true);
          }}
          registerId={register.uid}
          storeId={store.uid}
        />
      ) : null}
      {/* survey modal */}
      {isSurveyModalOpen ? (
        <EuiFlexGroup className="survey-page" direction="column">
          <EuiImage className="costco-logo" src={CostcoLogo} alt="" />
          <SurveyPageWeb
            onNewSurveyCode={handleNewSurveyCode}
            onClose={handleSurveyClose}
          />
        </EuiFlexGroup>
      ) : null}
    </EuiFlexGroup>
  );
};

export default WebsiteItemDetailPage;
