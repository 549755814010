import {
  EuiBottomBar,
  EuiHeader,
  EuiPageHeader,
  EuiPageSection,
  EuiText,
} from "@elastic/eui";
import "./qubes-page.scss";

const QubesPage: React.FC = () => {
  const pageContent = <div className="contents">contents</div>;
  return (
    <div className="_qubes_page">
      <EuiHeader theme="dark" position="fixed" className="header">
        <EuiText>this is the header</EuiText>
      </EuiHeader>
      <EuiHeader position="fixed">
        <EuiText>this is the menu</EuiText>
      </EuiHeader>
      <EuiPageHeader pageTitle="This is the page title"></EuiPageHeader>
      <EuiPageSection>{pageContent}</EuiPageSection>
      <EuiBottomBar paddingSize="s">this is the footer</EuiBottomBar>
    </div>
  );
};

export default QubesPage;
