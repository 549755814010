import { CostcoLogo, KrogerLogo, KrogerLogoBlue } from "../../../assets/assets";
import { ItemMaster } from "../../../model/pos/item-master";
import { Location } from "../../../services/geoapify-service";
import CostcoWebsiteHomePage from "../../costco/web/web-home-page/web-home-page";
import WebReceiptPage from "../../common/web/web-home-receipt/web-receipt-page";
import { CostcoWebLocationBanner } from "../../costco/web/web-location-banner/web-location-banner";
import { CostcoWebNavigationBanner } from "../../costco/web/web-navigation-banner/web-navigation-banner";
import { CostcoWebSessionBanner } from "../../costco/web/web-session-banner/web-session-banner";
import KrogerWebsiteHomePage from "../../kroger/web/web-home-page/web-home-page";
import { PathSeparator, WebItemPath } from "./web-item-path.ts/web-item-path";
import { KrogerWebLocationBanner } from "../../kroger/web/web-location-banner/web-location-banner";
import { KrogerWebSessionBanner } from "../../kroger/web/web-session-banner/web-session-banner";
import { WebCartPageProps } from "./web-cart-page";
import { WebHomePageProps } from "./web-home-page";
import WebItemDetailPage from "./web-item-detail-page/web-item-detail-page";
import { WebReceiptPageProps } from "./web-receipt-page";
import { CostcoWebItemDetailOrdering } from "../../costco/web/web-item-detail-ordering/web-item-detail-ordering";
import { KrogerWebItemDetailOrdering } from "../../kroger/web/web-item-detail-ordering/web-item-detail-ordering";
import { WebItemDetailOrderingProps } from "./web-item-detail-ordering";
import { CostcoWebItemFurtherDetails } from "../../costco/web/web-item-detail-further-details/web-item-detail-further-details";
import { KrogerWebItemFurtherDetails } from "../../kroger/web/web-item-detail-further-details/web-item-detail-further-details";
import { WebItemProps } from "./web-item-props";
import { KrogerWebItemDetailPricing } from "../../kroger/web/web-item-detail-pricing/web-item-detail-pricing";
import { CostcoWebItemDetailPricing } from "../../costco/web/web-item-detail-pricing/web-item-detail-pricing";
import WebCartPage from "./web-cart-page/web-cart-page";
import { CostcoWebCartDeliveryInfo } from "../../costco/web/web-cart-delivery-info/web-cart-delivery-info";
import { KrogerWebCartDeliveryInfo } from "../../kroger/web/web-cart-delivery-info/web-cart-delivery-info";
import ENV from "../../../services/env-service";

export type Company = "costco" | "kroger";

export const CompanyResolver = {
  subdomain: "kroger" as Company, //(window.location.hostname?.split(".")?.[0] ?? "kroger") as Company,

  getRootPageContents: (reroute: (url: string) => void) => {
    switch (CompanyResolver.subdomain) {
      case "costco":
      case "kroger": {
        return (
          <>
            <div
              className="button pos"
              onClick={() => reroute(`${ENV.REACT_ROUTER_BASENAME}/pos`)}
            >
              <img src={CompanyResolver.getLogoLight()} alt="logo" />
              <div style={{ float: "left", fontSize: "45px", fontWeight: 700 }}>
                Point of Sale
              </div>
            </div>
            <div
              className="button ecommerce"
              onClick={() => reroute(`${ENV.REACT_ROUTER_BASENAME}/web`)}
            >
              <img src={CompanyResolver.getLogoLight()} alt="logo" />
              <div style={{ float: "left", fontSize: "45px", fontWeight: 700 }}>
                E-Commerce
              </div>
            </div>
          </>
        );
      }
    }
  },

  getItemFilters: () => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return " and demoCategories like '%costco%' or demoCategories like '%club%'";
      }
      case "kroger": {
        return " and demoCategories like '%kroger%' or demoCategories like '%grocery%'";
      }
      default:
        throw new Error("don't know how to handle" + CompanyResolver.subdomain);
    }
  },

  getLogo: () => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return CostcoLogo;
      }
      case "kroger": {
        return KrogerLogoBlue;
      }
      default:
        throw new Error("don't know how to handle" + CompanyResolver.subdomain);
    }
  },

  getLogoLight: () => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return CostcoLogo;
      }
      case "kroger": {
        return KrogerLogo;
      }
      default:
        throw new Error("don't know how to handle" + CompanyResolver.subdomain);
    }
  },

  getWebHeader: (shoppingCartQuantity: number, location?: Location) => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return (
          <>
            <CostcoWebSessionBanner
              shoppingCartQuantity={shoppingCartQuantity}
            />
            <CostcoWebNavigationBanner />
            <CostcoWebLocationBanner
              city={location?.city ?? ""}
              state={location?.state ?? ""}
              postCode={location?.postCode ?? ""}
            />
          </>
        );
      }
      case "kroger": {
        return (
          <>
            <KrogerWebSessionBanner
              shoppingCartQuantity={shoppingCartQuantity}
            />
            <KrogerWebLocationBanner
              city={location?.city ?? ""}
              state={location?.state ?? ""}
              postCode={location?.postCode ?? ""}
            />
          </>
        );
      }
      default:
        throw new Error("don't know how to handle" + CompanyResolver.subdomain);
    }
  },

  getHomePage: (props: WebHomePageProps) => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return <CostcoWebsiteHomePage {...props} />;
      }
      case "kroger": {
        return <KrogerWebsiteHomePage {...props} />;
      }
      default:
        throw new Error("don't know how to handle" + CompanyResolver.subdomain);
    }
  },

  getReceiptPage: (props: WebReceiptPageProps) => {
    return <WebReceiptPage {...props} />;
  },

  getCartPage: (props: WebCartPageProps) => {
    return <WebCartPage {...props} />;
  },

  getCartDeliveryInfo: () => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return <CostcoWebCartDeliveryInfo />;
      }
      case "kroger": {
        return <KrogerWebCartDeliveryInfo />;
      }
      default:
        throw new Error("don't know how to handle" + CompanyResolver.subdomain);
    }
  },
  getShippingText: () => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return "Shipping and Handling for";
      }
      case "kroger": {
        return "Pickup for";
      }
      default:
        throw new Error("don't know how to handle" + CompanyResolver.subdomain);
    }
  },

  getWebItemPath: (item: ItemMaster) => {
    let separator: PathSeparator;
    switch (CompanyResolver.subdomain) {
      case "costco":
        separator = "forward-slash";
        break;
      case "kroger":
        separator = "arrow-right";
        break;
      default:
        throw new Error("don't know how to handle" + CompanyResolver.subdomain);
    }

    return <WebItemPath item={item} separator={separator} />;
  },

  getItemDetailPage: (props: WebItemProps) => {
    return <WebItemDetailPage {...props} />;
  },

  getItemDetailPricing: (props: WebItemProps) => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return <CostcoWebItemDetailPricing {...props} />;
      }
      case "kroger": {
        return <KrogerWebItemDetailPricing {...props} />;
      }
      default:
        throw new Error("don't know how to handle" + CompanyResolver.subdomain);
    }
  },

  getItemDetailOrdering: (props: WebItemDetailOrderingProps) => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return <CostcoWebItemDetailOrdering {...props} />;
      }
      case "kroger": {
        return <KrogerWebItemDetailOrdering {...props} />;
      }
      default:
        throw new Error("don't know how to handle" + CompanyResolver.subdomain);
    }
  },

  getItemDetailFurtherDetails: (props: WebItemProps) => {
    switch (CompanyResolver.subdomain) {
      case "costco": {
        return <CostcoWebItemFurtherDetails {...props} />;
      }
      case "kroger": {
        return <KrogerWebItemFurtherDetails {...props} />;
      }
      default:
        throw new Error("don't know how to handle" + CompanyResolver.subdomain);
    }
  },
};
