import "./survey-header.scss";

/**
 * The component controlling the survey header
 * @returns {JSX.Element}
 */
const SurveyHeader = () => {
  return (
    <div className="survey-header-container">
      <div className="brand">
        Cialis<span className="copyright">&reg;</span>
        <div className="text"> OTC</div>
      </div>
    </div>
  );
};

export default SurveyHeader;
