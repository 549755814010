import { useCallback, useEffect, useMemo, useState } from "react";
import { ItemMaster } from "../../../model/pos/item-master";
import "./website-base-page.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  registerStateSelector,
  setRegister,
  setStore,
} from "../../../state/slices/model-slice";
import { QubesService } from "../../../services/qubes-service";
import FakeLink from "../../../components/fake-link/fake-link";
import { GeoapifyService, Location } from "../../../services/geoapify-service";
import {
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiImage,
  EuiText,
} from "@elastic/eui";

import { CostcoLogo, ShoppingCartLogo } from "../../../assets/assets";
import WebsiteHomePage from "../home/website-home-page";
import { Link, useParams } from "react-router-dom";
import WebsiteItemDetailPage from "../item-detail/website-item-detail-page";
import { isEmpty } from "lodash";
import { ShoppingCartService } from "../../../services/shopping-cart-service";
import WebsiteCartPage from "../cart/website-cart-page";
import ReceiptPage from "../receipt/receipt-page";

/**
 * This is the page that controls all actions within the Ecommerce demo track and
 * renders all the appropriate child components
 * @returns {JSX.Element}
 */
const WebsiteBasePage = (): JSX.Element => {
  const dispatch = useDispatch();

  const params = useParams();

  const [shoppingCartQuantity, setShoppingCartQuantity] = useState(0);

  useEffect(() => {
    const sub = ShoppingCartService.get()
      .observable()
      .subscribe((next: Map<string, number>) => {
        if (next.size > 0) {
          let count = 0;
          next.forEach((entry) => {
            count += entry;
          });
          setShoppingCartQuantity(count);
        } else setShoppingCartQuantity(0);
      });

    return () => sub.unsubscribe();
  }, [setShoppingCartQuantity]);

  // Grab and set the items the POS is going to be playing with
  const [items, setItems] = useState<ItemMaster[]>([]);

  // The register we are running on
  const register = useSelector(registerStateSelector);

  const [location, setLocation] = useState<Location>();

  /**
   * Load the data model
   */
  useEffect(() => {
    GeoapifyService.get()
      .getLocationFromIP()
      .then(setLocation)
      .catch((err) => {
        console.error(err);
      });

    //load the items
    QubesService.loadItems()
      .then((resp) => {
        if (resp.data) {
          const sortedItems = resp.data
            .filter((it) => it.environment === "home page" && it.order)
            .sort((a, b) => {
              return a.order! - b.order!;
            });
          setItems(sortedItems);
        } else {
          throw new Error("Unable to load items");
        }
      })
      .catch((err) => {
        console.error(err);
      });

    //load the registers
    QubesService.loadRegisters()
      .then((resp) => {
        if (resp.data) {
          const register = resp.data.find((reg) =>
            reg.name.startsWith("ECommerce")
          );

          dispatch(setRegister(register!));
        } else {
          throw new Error("Unable to load registers");
        }
      })
      .catch((err) => {
        console.error(err);
      });
    //load the store
    QubesService.loadStores()
      .then((resp) => {
        if (resp.data && resp.data[0]) {
          dispatch(setStore(resp.data[0]));
        } else {
          throw new Error("Unable to load stores");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dispatch]);

  const row1Items: ItemMaster[] = useMemo(() => {
    return items?.slice(0, 4) ?? [];
  }, [items]);

  const row2Items: ItemMaster[] = useMemo(() => {
    return items?.slice(4, 8) ?? [];
  }, [items]);

  const row3Items: ItemMaster[] = useMemo(() => {
    return items?.slice(8, 12) ?? [];
  }, [items]);

  const currentItem: ItemMaster | undefined = useMemo(() => {
    if (!isEmpty(items) && params.itemUidOrName) {
      return items.find((it) => it.uid === params.itemUidOrName);
    }
  }, [params.itemUidOrName, items]);

  const resolvedContent = useCallback(() => {
    if (params?.itemUidOrName) {
      if (currentItem) {
        return <WebsiteItemDetailPage item={currentItem} />;
      } else return <EuiText size="m">Loading...</EuiText>;
    } else if (window.location.pathname?.includes("cart")) {
      return (
        <WebsiteCartPage
          itemsDatabase={items}
          customerLocation={location}
          register={register}
        />
      );
    } else if (
      window.location.pathname?.includes("receipt") &&
      params?.receiptUid
    ) {
      return (
        <ReceiptPage receiptUid={params.receiptUid} itemsDatabase={items} />
      );
    } else {
      return (
        <WebsiteHomePage
          row1Items={row1Items}
          row2Items={row2Items}
          row3Items={row3Items}
        />
      );
    }
  }, [
    params,
    items,
    currentItem,
    location,
    register,
    row1Items,
    row2Items,
    row3Items,
  ]);

  return (
    <EuiFlexGroup
      className="website-base-page"
      direction="column"
      alignItems="center"
      gutterSize="none"
    >
      <div className="web-session-banner">
        <div className="web-session-top-banner">
          Online Savings Starts Today, Shop Now!
        </div>
        <div className="web-session-links-banner">
          <FakeLink text="Costco Next" />
          <FakeLink text="While Supplies Last" />
          <FakeLink text="Online-Only" />
          <FakeLink text="Treasure Hunt" />
          <FakeLink text="What's New" />
          <FakeLink text="New Lower Prices" />
          <FakeLink text="Get Email Offers" />
          <FakeLink text="Customer Service" />
        </div>
        <EuiFlexGroup
          className="web-session-search-banner"
          justifyContent="spaceAround"
          alignItems="center"
        >
          <EuiFlexItem grow={false}>
            <Link to={"/web"}>
              <EuiImage className="costco-logo" src={CostcoLogo} alt="" />
            </Link>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFieldSearch
              className="search-box"
              placeholder="Search"
              fullWidth
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false} className="links">
            <EuiFlexGroup
              gutterSize="s"
              justifyContent="spaceAround"
              alignItems="center"
            >
              <FakeLink text="Account" />
              <div className="divider" />
              <FakeLink text="Orders & Returns" />
              <div className="divider" />
              <Link to={"/web/cart"}>
                <EuiFlexGroup
                  className="shopping-cart"
                  alignItems="center"
                  gutterSize="none"
                  justifyContent="center"
                >
                  <img
                    className="shopping-cart-image"
                    src={ShoppingCartLogo}
                    alt="shopping cart logo"
                  />
                  {shoppingCartQuantity > 0 ? (
                    <EuiFlexItem
                      className="shopping-cart-quantity"
                      grow={false}
                    >
                      {shoppingCartQuantity}
                    </EuiFlexItem>
                  ) : (
                    <EuiFlexItem className="shopping-cart-quantity-placeholder"></EuiFlexItem>
                  )}

                  <FakeLink text="Cart" />
                </EuiFlexGroup>
              </Link>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
      <div className="web-navigation-banner">
        <FakeLink text="Shop" />
        <FakeLink text="Grocery" />
        <FakeLink text="Same-Day" />
        <FakeLink text="Deals" />
        {/* <FakeLink text="Business Delivery" /> */}
        <FakeLink text="Optical" />
        <FakeLink text="Pharmacy" />
        <FakeLink text="Services" />
        <FakeLink text="Photo" />
        <FakeLink text="Travel" />
        <FakeLink text="Membership" />
        <FakeLink text="Locations" />
      </div>
      <div className="web-location-banner">
        <div className="web-location-banner-my-warehouse">
          <div className="label">My Warehouse</div>
          <div className="value">
            <EuiIcon type={"mapMarker"} />
            {location?.city}
          </div>
        </div>
        <div className="web-location-banner-my-zipcode">
          <div className="label">Delivery Location</div>
          <div className="value">
            <EuiIcon type={"container"} />
            {location?.postCode}
          </div>
        </div>
      </div>
      {resolvedContent()}
    </EuiFlexGroup>
  );
};

export default WebsiteBasePage;
