import "./survey-doctor-stop.scss";

const SurveyDoctorStop = () => {
  return (
    <div className="survey-doctor-stop-container">
      <div className="top">
        <img src={require("../../../assets/stopsign.jpg")} alt="stopsign" />
        <div className="warning-text">Do Not Use Cialis® OTC.</div>
      </div>
      <div className="bold-text">
        You will not be able to purchase Cialis® OTC.
      </div>
      <div className="text">
        Taking over the counter Cialis® is not safe for you and you should speak
        to a healthcare professional.
      </div>
      <div className="text">
        Your ED treatment should be under a doctor's supervision.
      </div>
      <div className="text">
        Thank you for completing this health survey assessment.
      </div>
    </div>
  );
};

export default SurveyDoctorStop;
