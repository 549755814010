import "./survey-stop.scss";

const SurveyStop = () => {
  return (
    <div className="survey-stop-container">
      <div className="top">
        <img src={require("../../../assets/stopsign.jpg")} alt="stopsign" />
        <div className="warning-text">Do Not Use Cialis® OTC.</div>
      </div>
      <div className="bold-text">
        You will not be able to purchase Cialis® OTC.
      </div>
      <div className="text">
        Thank you for participating in this health survey assessment.
      </div>
    </div>
  );
};

export default SurveyStop;
