import { useNavigate } from "react-router-dom";
import ENV from "../../services/env-service";
import "./home-page.scss";
import { useCallback } from "react";
import CostcoLogo from "../../assets/costco-logo.svg";

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  const cleanPath = useCallback((path: string) => {
    if (path.startsWith("//")) return path.substring(1);
    else return path;
  }, []);
  /**
   * Navigate to a different page via React Router
   */
  const reroute = (suffix: string) => {
    navigate(cleanPath(suffix));
  };

  return (
    <div className="_home_page">
      <div
        className="button survey"
        onClick={() => reroute(`${ENV.REACT_ROUTER_BASENAME}/survey`)}
      >
        <div className="text">Cialis HSA</div>
      </div>
      <div
        className="button pos"
        onClick={() => reroute(`${ENV.REACT_ROUTER_BASENAME}/pos`)}
      >
        <img src={CostcoLogo} alt="costco logo" />
        <div style={{ float: "left", fontSize: "45px", fontWeight: 700 }}>
          Point of Sale
        </div>
      </div>
      <div
        className="button ecommerce"
        onClick={() => reroute(`${ENV.REACT_ROUTER_BASENAME}/web`)}
      >
        <img src={CostcoLogo} alt="costco logo" />
        <div style={{ float: "left", fontSize: "45px", fontWeight: 700 }}>
          E-Commerce
        </div>
      </div>
    </div>
  );
};

export default HomePage;
