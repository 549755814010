import "./website-home-page.scss";
import { EuiFlexGroup, EuiFlexItem, EuiImage, EuiSpacer } from "@elastic/eui";

import { HomePageShoppingBanner1 } from "../../../assets/assets";
import HomePageItemRow from "../../../components/home-page-item-row/home-page-item-row";
import { ItemMaster } from "../../../model/pos/item-master";

export type WebsiteHomePageProps = {
  row1Items: ItemMaster[];
  row2Items: ItemMaster[];
  row3Items: ItemMaster[];
};

/**
 * This is the page that controls all actions within the POS demo track and
 * renders all the appropriate child components
 * @returns {JSX.Element}
 */
const WebsiteHomePage = ({
  row1Items,
  row2Items,
  row3Items,
}: WebsiteHomePageProps): JSX.Element => {
  return (
    <EuiFlexGroup
      className="website-home-page"
      direction="column"
      alignItems="center"
      justifyContent="center"
      gutterSize="none"
    >
      <EuiSpacer size="l" />
      <EuiFlexItem className="sale-banner" grow={false}>
        <EuiImage
          src={HomePageShoppingBanner1}
          alt="shopping 1"
          className="rounded-corner-image"
        />
      </EuiFlexItem>
      <EuiSpacer size="xl" />
      <HomePageItemRow key="row1" items={row1Items} />
      <EuiSpacer size="xl" />
      <HomePageItemRow key="row2" items={row2Items} />
      <EuiSpacer size="xl" />
      <HomePageItemRow key="row3" items={row3Items} />
    </EuiFlexGroup>
  );
};

export default WebsiteHomePage;
