import { useEffect, useMemo, useState } from "react";
import BarCode from "../../../assets/bar-code.svg";
import { QubesService } from "../../../services/qubes-service";
import { CodeStatus } from "../../survey-modal/survey-modal";
import "./survey-code.scss";

export type SurveyCodeProps = {
  expiriDate: string;
  code: string | undefined;
};

const SurveyCode = ({ expiriDate, code }: SurveyCodeProps) => {
  // Whether or not the code is still valid
  const [codeStatus, setCodeStatus] = useState<CodeStatus>();

  // Set up and fire the API call every few seconds to keep the UI up to date
  useEffect(() => {
    /**
     * Establish whether or not the code is valid
     */
    const isCodeStillValid = async () => {
      if (!code) return;

      try {
        // See if this QR code exists in POSCorrelationCode
        const qrCodeResult = await QubesService.loadQRCode(code);

        if (qrCodeResult.data) {
          const qrCodes = qrCodeResult.data;
          if (qrCodes.length > 1) {
            console.error(
              `Multiple records with the same QR Code found | ${qrCodes
                .map((qrc) => qrc.uid)
                ?.join(",")}`
            );
            throw new Error("Multiple records with the same QR code found");
          } else {
            //we have a single QR code record
            const qr = qrCodes[0];

            // check the expiration - append the EoD time with no timezone
            // so that the created date is EoD for the current browser's time
            // zone
            const expirationDate: Date = new Date(
              `${qr.expirationDate}T23:59:59.999`
            );

            if (new Date() > expirationDate || !qr.active) {
              setCodeStatus({
                status: "Denied",
                reason: "QR Code expired",
                message: "Codes must be used within 14 days of creation",
              });
            } else {
              // we have a valid, unexpired code. Check if the code has already been used
              const posEvents = await QubesService.loadPosEventResult(code);
              if (
                posEvents.data &&
                posEvents.data.length > 0 &&
                posEvents.data.some((posEvent) => posEvent.saleCompleted) // has a sale been completed?
              ) {
                setCodeStatus({
                  status: "Denied",
                  reason: "QR Code already used",
                  message: "A new survey must completed to purchase this item",
                });
              } else {
                setCodeStatus({
                  status: "Granted",
                  reason: "",
                  message: "",
                });
              }
            }
          }
        } else {
          throw new Error("QRCode lookup result had no `data` element");
        }
      } catch (err) {
        setCodeStatus({
          status: "Error",
          reason: "Unexpected error has occurred",
          message: "An unexpected error has occurred. Please try again.",
        });
        console.error(err);
      }
    };

    let timer: ReturnType<typeof setTimeout>;

    if (!codeStatus || codeStatus.status === "Granted") {
      timer = setTimeout(() => isCodeStillValid(), 10000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [code, codeStatus]);

  /**
   * The renderer for the code based on the code status
   */
  const renderCodeBasedOnStatus = useMemo(() => {
    if (!codeStatus || codeStatus.status === "Granted") {
      return (
        <>
          <div className="code">{code}</div>
          <img src={BarCode} alt="barcode" />
          <div className="text">Your purchase code will expire on:</div>
          <div className="text date">{expiriDate}</div>
          <div className="text">
            If you do not use the code before this date you will need to take
            the survey again.
          </div>
        </>
      );
    } else if (codeStatus.reason === "QR Code expired") {
      return (
        <>
          <div className="code expired">{code}</div>
          <div className="text expired">
            Your purchase code has expired. You will need to take the survey
            again to get a new code.
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="code expired">{code}</div>
          <div className="text expired">
            Your purchase code has already been used. You will need to take the
            survey again to get a new code.
          </div>
        </>
      );
    }
  }, [code, codeStatus, expiriDate]);

  return (
    <div className="survey-code-container">
      <div className="header">
        HSA completed successfully. You may now purchase Cialis® over the
        counter. You will need this code to complete your product purchase.
      </div>
      {renderCodeBasedOnStatus}
      <div className="text">
        Thank you for completing this health survey assessment.
      </div>
    </div>
  );
};

export default SurveyCode;
