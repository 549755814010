import "./survey-landing.scss";

/**
 * The props needed for the landing page to work
 * @prop {() => void} iterateContent - The method to navigate to another set of content
 */
export type SurveyLandingProps = {
  iterateContent: () => void;
};

const SurveyLanding = ({ iterateContent }: SurveyLandingProps) => {
  return (
    <div className="survey-landing-container">
      <div className="top">
        <div className="title">
          Cialis
          <div className="restricted">&reg;</div>
        </div>
        <div className="sub-title">OTC</div>
        <div className="description">
          <div className="title">Health Survey Assessment</div>
          <div className="instructions">
            You will need to complete this survey each time you want to purchase
            to determine if Cialis&reg; OTC is appropriate for you based on your
            health history.
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="begin-button" onClick={() => iterateContent()}>
          BEGIN
        </div>
        <div className="copyright">&copy; 2021, 2023</div>
      </div>
    </div>
  );
};

export default SurveyLanding;
