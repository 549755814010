import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SurveyLandingWeb from "../../components/survey-content-web/survey-landing-web/survey-landing-web";
import SurveyWelcome from "../../components/survey-content/survey-welcome/survey-welcome";
import SurveyWrapper from "../../components/survey-content/survey-wrapper/survey-wrapper";
import SurveyED from "../../components/survey-content/survey-ed/survey-ed";
import SurveyWarning from "../../components/survey-content/survey-warning/survey-warning";
import SurveyDoctorStop from "../../components/survey-content/survey-doctor-stop/survey-doctor-stop";
import SurveyCode from "../../components/survey-content/survey-code/survey-code";
import { Question } from "../../model/sanofi-otc/question";
import SurveyQuestion from "../../components/survey-content/survey-question/survey-question";
import { Survey, SurveyData } from "../../model/sanofi-otc/survey";
import { Customer } from "../../model/sanofi-otc/customer";
import { QubesService } from "../../services/qubes-service";
import { QRCode } from "../../model/sanofi-otc/qr-code";
import SurveyStop from "../../components/survey-content/survey-stop/survey-stop";
import { Answer } from "../../model/sanofi-otc/answer";
import { cloneDeep } from "lodash";
import { v4 } from "uuid";
import "./survey-page-web.scss";

// The order of the main views
const views: string[] = [
  "landing",
  "welcome",
  "ed",
  "warning",
  "questions",
  "code",
];

// The questions to ask
const questions: Question[] = [
  {
    order: 1,
    header: "Are you over 18?",
    details: "This medicine is only for men who are 18 years of age and older.",
    acceptedAnswer: "yes",
    uid: "one",
  },
  {
    order: 2,
    header: "Is your birth gender male?",
    details:
      "Cialis® OTC has only been shown to work in people with ED who were assigned male at birth and have a penis.",
    acceptedAnswer: "yes",
    uid: "two",
  },
  {
    order: 3,
    header: "Do you take Nitrates?",
    details:
      'Taking Cialis® OTC with "Poppers" (nitrates) could lead to a dangerous drop in blood pressure, which can lead to a stroke or heart attack.',
    acceptedAnswer: "no",
    last: true,
    isDoctorQuestion: true,
    uid: "three",
  },
];

type SurveyPageWebProps = {
  onNewSurveyCode: (newSurveyCode: string, qrUid: string) => void;
  onClose: (addToCart: boolean) => void;
};

/**
 * The main page for the Survey that controls which view is currently being rendered
 * @returns {JSX.Element}
 */
const SurveyPageWeb = ({ onNewSurveyCode, onClose }: SurveyPageWebProps) => {
  // The current content that should be rendered
  const [currentContent, setCurrentContent] = useState<string>(views[0]);

  // Show doctor stop page and end survey
  const [showDoctorStop, setShowDoctorStop] = useState<boolean>(false);

  // Show regular stop page and end survey
  const [showStop, setShowStop] = useState<boolean>(false);

  // The current question we are on
  const [currentQuestion, setCurrentQuestion] = useState<Question>(
    questions[0]
  );

  // The answer to the current question
  const [currentAnswer, setCurrentAnswer] = useState<string>("unanswered");

  // The current instance of the survey
  const [currentSurvey, setCurrentSurvey] = useState<Survey>();

  // The POS Correlation Code (QRCode) of the completed survey
  const [currentQRCode, setCurrentQRCode] = useState<string>(
    Math.random().toString(36).slice(2, 9).toUpperCase()
  );

  // The expiration date
  const [expirationDate, setExpirationDate] = useState<string>();

  const currentQRCodeRef = useRef<string>();

  useEffect(() => {
    currentQRCodeRef.current = currentQRCode;
  }, [currentQRCode]);

  const [currentCustomer, setCurrentCustomer] = useState<Customer>();

  const handleSurveyStarted = useCallback(async () => {
    console.info("Insert a new Survey record");
    try {
      if (!currentCustomer) {
        console.error("Customer is required");
        return;
      }
      const newSurvey: Survey = {
        uid: "set_by_insert_operation",
        active: true,
        customerUid: currentCustomer?.uid,
        startedAt: new Date().toISOString(),
        status: "Started",
        surveyData: {} as SurveyData,
      };
      const insertResult = await QubesService.insertSurvey(newSurvey);
      if (!insertResult.success || !insertResult.keys?.uid) {
        throw new Error("Unsuccessful insert of the survey");
      } else {
        newSurvey.uid = insertResult.keys.uid;
        setCurrentSurvey(newSurvey);
        console.info(`New survey successfully inserted | ${newSurvey.uid}`);
      }
    } catch (err) {
      console.error("Error inserting survey", err);
    }
  }, [currentCustomer]);

  useEffect(() => {
    //load the customer
    QubesService.loadCustomersResult()
      .then((resp) => {
        if (resp.data?.[0]) {
          setCurrentCustomer(resp.data[0]);
        } else {
          throw new Error("Unable to load customer");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // Reset the current answer to "unanswered" when the current question changes
  useEffect(() => {
    setCurrentAnswer("unanswered");
  }, [currentQuestion]);

  useEffect(() => {
    const newAnswer: Answer = {
      uid: v4(),
      questionUid: currentQuestion.uid,
      response: currentAnswer,
      extraConfig: {},
    };

    setCurrentSurvey((oldSurvey) => {
      if (!oldSurvey) return;

      const surveyDataClone: SurveyData =
        cloneDeep(oldSurvey?.surveyData) || {};
      const surveyClone: Survey = cloneDeep(oldSurvey) || ({} as Survey);

      surveyDataClone[currentQuestion.uid] = newAnswer;

      surveyClone.surveyData = surveyDataClone;

      return surveyClone;
    });
  }, [currentAnswer, currentQuestion.uid]);

  const handleSurveyComplete = useCallback(
    async (correlationCode?: string) => {
      if (!currentCustomer) {
        console.error("Customer is required");
        return;
      }
      if (!currentSurvey) {
        console.error("Current survey is not defined");
        return;
      }
      console.info("Update the Survey record");
      try {
        const surveyClone: Survey = {
          ...currentSurvey,
          status: correlationCode
            ? "CompletedOtcAllowed"
            : "CompletedOtcDenied",
          completedAt: new Date().toISOString(),
        };
        const updateResult = await QubesService.updateSurvey(surveyClone);
        if (
          !updateResult.success ||
          !updateResult.numberOfRowsUpdated ||
          updateResult.numberOfRowsUpdated < 1
        ) {
          throw new Error("Unsuccessful update of the survey");
        } else {
          setCurrentSurvey(surveyClone);
          console.info(`Survey successfully updated | ${surveyClone.uid}`);
        }
      } catch (err) {
        console.error("Error inserting survey", err);
      }

      if (correlationCode) {
        console.info("Insert the PosCorrelationCode record");
        try {
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 14);

          setExpirationDate(expirationDate.toLocaleDateString("en-US"));

          const posCorrCode: QRCode = {
            uid: "set_by_insert_operation",
            surveyUid: currentSurvey.uid,
            active: true,
            expirationDate: expirationDate.toISOString().split("T")[0],
            correlationCode: correlationCode,
          };
          const qrInsertResult = await QubesService.insertQRCode(posCorrCode);
          if (!qrInsertResult.success || !qrInsertResult.keys?.uid) {
            throw new Error("Unsuccessful insert of the QR Code");
          } else {
            posCorrCode.uid = qrInsertResult.keys.uid;
            console.info(`QR code successfully inserted | ${posCorrCode.uid}`);
            onNewSurveyCode(correlationCode, posCorrCode.uid);
          }
        } catch (err) {
          console.error("Error inserting qr code", err);
        }
      } else {
        console.info("No correlation code so no QR code record created");
      }

      setCurrentSurvey(undefined);
    },
    [currentCustomer, currentSurvey, onNewSurveyCode]
  );

  useEffect(() => {
    if (showDoctorStop || showStop) {
      handleSurveyComplete();
    } else if (currentContent === "code") {
      handleSurveyComplete(currentQRCodeRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContent, showDoctorStop, showStop]);

  /**
   * Render the appropriate content
   */
  const renderContent = useMemo(() => {
    switch (currentContent) {
      case "code":
        return (
          <SurveyCode expiriDate={expirationDate || ""} code={currentQRCode} />
        );
      case "questions":
        return (
          <SurveyQuestion
            answer={(answer: string) => setCurrentAnswer(answer)}
            question={currentQuestion}
          />
        );
      case "warning":
        return <SurveyWarning />;
      case "ed":
        return <SurveyED />;
      default:
        return <SurveyWelcome />;
    }
  }, [currentContent, currentQRCode, currentQuestion, expirationDate]);

  /**
   * Handles resetting the survey back to the original state
   */
  const handleResetSurvey = useCallback(() => {
    setCurrentContent(views[0]);
    setShowDoctorStop(false);
    setShowStop(false);
    setCurrentAnswer("unanswered");
    setCurrentQuestion(questions[0]);
    setCurrentQRCode(Math.random().toString(36).slice(2, 9).toUpperCase());
    onClose(true);
  }, [onClose]);

  /**
   * Render the right main view
   */
  const renderMainView = useMemo(() => {
    /**
     * Handle navigating to the next view
     */
    const iterateContent = () => {
      if (!currentContent) return;

      if (currentContent === "questions") {
        if (currentAnswer === "unanswered") {
          return;
        } else if (
          currentAnswer !== currentQuestion.acceptedAnswer &&
          currentQuestion.isDoctorQuestion
        ) {
          setShowDoctorStop(true);
          return;
        } else if (currentAnswer !== currentQuestion.acceptedAnswer) {
          setShowStop(true);
          return;
        } else if (currentQuestion.order >= questions.length) {
          // no op so we continue with the normal flow iteration
        } else {
          setCurrentQuestion(questions[currentQuestion.order]);
          return;
        }
      }

      // Grab the index of the current content
      const currentIndex = views.findIndex((view) => view === currentContent);

      if (currentIndex === views.length - 1) {
        if (!currentQRCode) {
          console.log("Error: No QR code created.");
        } else {
          handleResetSurvey();
        }
      } else {
        // Move on to the next view
        setCurrentContent(views[currentIndex + 1]);
      }
    };

    if (showDoctorStop) {
      // If the customer needs to speak with a doctor
      // we will render the doctor stop and exit path
      return (
        <SurveyWrapper
          iterateContent={handleResetSurvey}
          atEnd={true}
          isWeb={true}
          onCancel={() => onClose(false)}
        >
          <SurveyDoctorStop />
        </SurveyWrapper>
      );
    } else if (showStop) {
      // If the drug is unsafe for the customer
      // we will render the stop and exit path
      return (
        <SurveyWrapper
          iterateContent={handleResetSurvey}
          atEnd={true}
          isWeb={true}
          onCancel={() => onClose(false)}
        >
          <SurveyStop />
        </SurveyWrapper>
      );
    } else if (currentContent === "landing") {
      // If the survey just started
      return (
        <SurveyLandingWeb
          iterateContent={() => {
            handleSurveyStarted();
            setCurrentContent(views[1]);
          }}
          onCancel={() => onClose(false)}
        />
      );
    } else {
      // If we are in the normal path

      const index = views.findIndex((view) => view === currentContent);

      return (
        <SurveyWrapper
          iterateContent={iterateContent}
          atEnd={index === views.length - 1 || false}
          isWeb={true}
          onCancel={() => onClose(false)}
          codeGenerated={!!currentQRCode}
        >
          {renderContent}
        </SurveyWrapper>
      );
    }
  }, [
    currentAnswer,
    currentContent,
    currentQRCode,
    currentQuestion.acceptedAnswer,
    currentQuestion.isDoctorQuestion,
    currentQuestion.order,
    handleResetSurvey,
    handleSurveyStarted,
    onClose,
    renderContent,
    showDoctorStop,
    showStop,
  ]);

  return (
    <div className="survey-page-container-web">
      <div className="inner-container">{renderMainView}</div>
    </div>
  );
};

export default SurveyPageWeb;
