import { AxiosResponse } from "axios";
import ENV from "../services/env-service";
import HttpsService from "../services/https-service";
import {
  AuthToken,
  QubesLoginRequest,
  QubesLoginResponse,
} from "./protocol/login.protocol";
import { QubesUser } from "./model/model";
import { QubesPingRequest, QubesPingResponse } from "./protocol/ping.protocol";
import {
  BiqlQueryRequest,
  BiqlQueryResponse,
  DataFormats,
} from "./protocol/biql-query.protocol";
import { CrudJson, CrudResponse } from "./protocol/crud.protocol";

// type SQL = string;
type BIQL = string;
type AppSpace = string;
// type SessionUID = string;

export class QubesAPI {
  private static _instance = new QubesAPI();

  private baseUrl: URL;
  private XSESS: AuthToken | undefined;
  private LOGGA: unknown;

  static get(): QubesAPI {
    return this._instance;
  }

  private constructor(baseUrl?: URL) {
    this.baseUrl = baseUrl ?? new URL("http://localhost:8080");
    this.LOGGA = window.console;
  }

  setXSESS(xsess: AuthToken) {
    this.XSESS = xsess;
  }

  // public static var Default403Behavior: Void->Void = JQ.noop;

  // static function getDoneFcn(fcn: Dynamic->Void): Dynamic->String->JQXHR->Void {
  //     return function(data: Dynamic, str: String, jqxhr: JQXHR){
  //         if(fcn != null) {
  //             try {
  //                 fcn(data);
  //             } catch (err: Dynamic) {
  //                 LOGGA.error("Error processing success fcn of an ajax call", err);
  //             }
  //         }
  //     };
  // }

  // static function getFailFcn(?failFcn: AjaxException->Void):  JQXHR->String->String->Void {
  //     return function(jqXHR: JQXHR, textStatus: String, errorThrown: String): Void{
  //         var error_message:String = errorThrown;
  //         if (jqXHR.message.isNotBlank()) {
  //             error_message = jqXHR.message;
  //         } else if (jqXHR.responseText.isNotBlank()) {
  //             error_message = jqXHR.responseText;
  //         } else if (error_message.isBlank()){
  //             error_message = "{no error msg from server}";
  //         }
  //         LOGGA.error("API error handler: Status " + jqXHR.status + " | " + error_message);
  //         LOGGA.debug("jqXHR\n--" + haxe.Json.stringify(jqXHR));

  //         if(jqXHR.status == 403 && Default403Behavior != null){
  //             Default403Behavior();
  //         }

  //         var exc: AjaxException = new AjaxException();
  //         exc.message = error_message;
  //         exc.statusCode = jqXHR.status;
  //         if(failFcn != null)
  //             failFcn(exc);
  //     };
  // }

  // static function getCrudFailFcn(?crudFailFcn: CrudResponse->Void):  JQXHR->String->String->Void {
  //     var failFcn = getFailFcn(function(exc: AjaxException): Void {
  //          var crudResponse = {
  //                 errorMessage: exc.message,
  //                 serverStackTrace: null,
  //                 success: false
  //             };
  //         if(crudFailFcn != null)
  //             crudFailFcn(crudResponse);
  //     });
  //     return failFcn;
  // }

  // static function getAlwaysFcn(?alwaysFcn: Dynamic->Void):   Dynamic->String->Dynamic->Void {
  //     return function(dataOrJqXHR: Dynamic, str: String, dyn: Dynamic){
  //         if(alwaysFcn != null)
  //             alwaysFcn(dataOrJqXHR);
  //     };
  // }

  // static function applyHandlers(jqXHR: JQXHR, fcns: AjaxHandlers): Void {
  //     jqXHR.done(getDoneFcn(fcns.done));
  //     jqXHR.fail(getFailFcn(fcns.fail));
  //     jqXHR.always(getAlwaysFcn(fcns.always));
  // }

  // static function applyCrudHandlers(jqXHR: JQXHR, fcns: CrudHandlers): Void {
  //     jqXHR.done(getDoneFcn(fcns.done));
  //     jqXHR.fail(getCrudFailFcn(fcns.fail));
  //     jqXHR.always(getAlwaysFcn(fcns.always));
  // }

  /**
   * Login to a qubes server using the provided credentials
   * @param {string} un - Username of the user
   * @param {string} pw - Password of the user
   * @returns an instance of `QubesUser` if login is successful
   * @throws an error if login fails for any reason
   */
  async login(un: string, pw: string): Promise<QubesUser> {
    try {
      const https = HttpsService.getHttps();
      const res = await https?.post<
        QubesLoginResponse,
        AxiosResponse<QubesLoginResponse, QubesLoginRequest>,
        QubesLoginRequest
      >(
        `${ENV.PUBLIC_URL}/api/login`,
        {
          u: un,
          p: pw,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      );
      //handle the response
      if (res?.data?.success) {
        return res.data.user;
      } else {
        const err = res?.data?.error ?? "Login Attempt Failed";
        throw err;
      }
    } catch (err) {
      //handle the error
      console.error(err);
      throw err;
    }
  }

  async logout(callbak: (success: boolean) => void): Promise<void> {
    const https = HttpsService.getHttps();
    try {
      await https?.post("/api/logout");
      callbak(true);
    } catch (err) {
      //handle the error
      console.error(err);
      callbak(false);
    }
  }

  /**
   * Ping the server to confirm if authtoken is authenticated.
   *
   * Expects that the XSESS property has been set on the client
   *
   * @returns instance of `QubesUser` if logged in
   * @throws an error if no XSESS property has been set, or if the user is not logged in
   */
  async ping(): Promise<QubesUser> {
    try {
      const https = HttpsService.getHttps();
      const res = await https?.post<
        QubesPingResponse,
        AxiosResponse<QubesPingResponse, QubesPingRequest>,
        QubesPingRequest
      >(
        "/api/ping",
        {},
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            ...(this.XSESS ? { "X-SESS": this.XSESS } : {}),
          },
        }
      );

      //handle the response
      if (res?.data?.loggedIn) {
        return res.data.user;
      } else {
        throw new Error("User is not logged in");
      }
    } catch (err) {
      //handle the error
      console.error(err);
      throw err;
    }
  }

  // public static function prettyPrintSql(sql:SQL, htmlResponse:Bool=true, indent:String="\t"):String {
  //  var s:String;
  //        var ajaxOptions: AjaxOptions = Default_Ajax_Opts;
  //        getDefaultAjaxOptions(
  //            function(data: Dynamic, textStatus: String, jqXHR: JQXHR): Void {
  //                s = cast data;
  //            },
  //            function(exc: AjaxException): Void {
  //                LOGGA.error("Pretty Print failed");
  //                s = sql;
  //            }
  //         );
  //        ajaxOptions.async = false;
  //        ajaxOptions.url = baseUrl + "/api/sqlPrettyPrint";
  //        ajaxOptions.data = {
  //                sql: sql,
  //                htmlResponse: htmlResponse,
  //                indent: indent
  //            };
  //        JQ.ajax(ajaxOptions);

  //        return s;
  // }

  // public static function prettyPrintQubql(query:BIQL, htmlResponse:Bool=true):String {
  //     var s:String;
  //     var ajaxOptions: AjaxOptions = getDefaultAjaxOptions(
  //         function(data: Dynamic, textStatus: String, jqXHR: JQXHR): Void {
  //             s = cast data;
  //         },
  //         function(exc: AjaxException): Void {
  //             LOGGA.error("Pretty Print failed");
  //             s = query;
  //         }
  //      );
  //     ajaxOptions.async = false;
  //     ajaxOptions.url = baseUrl + "/api/qubqlPrettyPrint";
  //     ajaxOptions.data = {
  //             query: query,
  //             htmlResponse: htmlResponse
  //         };
  //     JQ.ajax(ajaxOptions);

  //     return s;
  // }

  // public static function kvPut(key: String, json: Any, callbak: Bool->Void): Void {
  //     var ajaxOptions: AjaxOptions = getDefaultAjaxOptions(
  //             function(data: Dynamic, textStatus: String, jqXHR: JQXHR): Void {
  //                 callbak(true);
  //             },
  //             function(exc: AjaxException): Void {
  //                 callbak(false);
  //             }
  //          );
  //     ajaxOptions.url = baseUrl + "/api/kv/" + key;
  //     ajaxOptions.async = false;
  //     ajaxOptions.dataType = "TEXT";
  //     ajaxOptions.contentType = "application/json";
  //     ajaxOptions.data = json;
  //     ajaxOptions.processData = false;

  //     JQ.ajax(ajaxOptions);
  // }

  // public static function kvGet(key: String, callbak: Dynamic->Void): Void {
  //     var ajaxOptions: AjaxOptions = getDefaultAjaxOptions(
  //             function(data: Dynamic, textStatus: String, jqXHR: JQXHR): Void {
  //                 callbak(data);
  //             },
  //             function(exc: AjaxException): Void {

  //             }
  //     );

  //     ajaxOptions.url = baseUrl + "/api/kv/" + key;
  //     ajaxOptions.async = false;
  //     ajaxOptions.dataType = "JSON";
  //     ajaxOptions.type = "GET";
  //     ajaxOptions.contentType = "application/json";

  //     JQ.ajax(ajaxOptions);
  // }

  /**
   * Asynchronously inserts a row based on the specified {@link CrudJson},
   * and expects generic parameters T to specify the object being
   * inserted, and K to specify the keys of the inserted type
   *
   * @param {CrudJson} json - An instance of CrudJson
   * @returns the CrudResponse
   */
  async insertRow<T, K>(json: CrudJson<T>): Promise<CrudResponse<K>> {
    return this._crudOp<T, K>("/api/insert", json);
  }

  /**
   * Asynchronously upserts a row based on the specified {@link CrudJson},
   * and expects generic parameters T to specify the object being
   * inserted, and K to specify the keys of the upserted type
   *
   * @param {CrudJson} json - An instance of CrudJson
   * @returns the CrudResponse
   */
  async upsertRow<T, K>(json: CrudJson<T>): Promise<CrudResponse<K>> {
    return this._crudOp<T, K>("/api/upsertRow", json);
  }

  /**
   * Asynchronously updates a row based on the specified {@link CrudJson},
   * and expects generic parameters T to specify the object being
   * inserted, and K to specify the keys of the updated type
   *
   * @param {CrudJson} json - An instance of CrudJson
   * @returns the CrudResponse
   */
  async updateRow<T, K>(json: CrudJson<T>): Promise<CrudResponse<K>> {
    return this._crudOp<T, K>("/api/updateRow", json);
  }

  /**
   * Asynchronously deletes a row based on the specified {@link CrudJson},
   * and expects generic parameters T to specify the object being
   * deleted, and K to specify the keys of the deleted type
   *
   * @param {CrudJson} json - An instance of CrudJson
   * @returns the CrudResponse
   */
  async deleteRow<T, K>(json: CrudJson<T>): Promise<CrudResponse<K>> {
    return this._crudOp<T, K>("/api/deleteRow", json);
  }

  private async _crudOp<T, K>(
    url: String,
    json: CrudJson<T>
  ): Promise<CrudResponse<K>> {
    if (!json.appSpace) {
      throw new Error("AppSpace must be specified");
    }

    try {
      const https = HttpsService.getHttps();
      const res = await https?.post<
        CrudResponse<K>,
        AxiosResponse<CrudResponse<K>, CrudJson<T>>,
        CrudJson<T>
      >(`${ENV.PUBLIC_URL}${url}`, json, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          ...(this.XSESS ? { "X-SESS": this.XSESS } : {}),
        },
      });
      return res?.data;
    } catch (err) {
      //handle the error
      console.error(err);
      throw err;
    }
  }

  // public static function ping(?handlers: AjaxHandlers): Dynamic {
  //     var ajaxOptions: AjaxOptions = Default_Ajax_Opts;
  //     ajaxOptions.url = baseUrl + "/api/ping";

  //     var jqXHR: JQXHR = JQ.ajax(ajaxOptions);
  //     applyHandlers(jqXHR, handlers);
  //     return jqXHR;
  // }

  // public static function sqlQuery(query: SQL, handlers: AjaxHandlers, route: String): Dynamic {
  //     var obj: Array<Dynamic>;
  //     var ajaxOptions: AjaxOptions = Default_Ajax_Opts;

  //     var json : Dynamic;
  //     json = {
  //         "route":route,
  //         "query":query,
  //         "mode":"readonly",
  //         "uid": UidGenerator.create()
  //     };

  //     ajaxOptions.url = baseUrl + "/api/sql/query";
  //     ajaxOptions.data = Json.stringify(json);
  //     var jqXHR: JQXHR = JQ.ajax(ajaxOptions);
  //     applyHandlers(jqXHR, handlers);
  //     return jqXHR;
  // }

  async biqlQuery<T>(
    query: BIQL,
    appSpace?: AppSpace,
    dataFormat?: DataFormats
  ): Promise<BiqlQueryResponse<T>> {
    var json: BiqlQueryRequest = {
      query: query,
      dataFormat: dataFormat ?? "concise",
    };
    if (appSpace) json.appSpace = appSpace;

    try {
      const https = HttpsService.getHttps();
      const res = await https?.post<
        BiqlQueryResponse<T>,
        AxiosResponse<BiqlQueryResponse<T>, BiqlQueryRequest>,
        BiqlQueryRequest
      >(`${ENV.PUBLIC_URL}/api/query`, json, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          ...(this.XSESS ? { "X-SESS": this.XSESS } : {}),
        },
      });
      return res?.data;
    } catch (err) {
      //handle the error
      console.error(err);
      throw err;
    }
  }

  // public static function multiRequest(json: Array<MethodInvocation>, handlers: AjaxHandlers): JQXHR {
  //     var ajaxOptions: AjaxOptions = Default_Ajax_Opts;
  //     ajaxOptions.url = baseUrl + "/api/multirequest";
  //     ajaxOptions.data = Json.stringify(json);

  //     var jqXHR: JQXHR = JQ.ajax(ajaxOptions);
  //     applyHandlers(jqXHR, handlers);
  //     return jqXHR;
  // }
}
