import { EuiFlexGroup, EuiFlexItem, EuiImage, EuiText } from "@elastic/eui";
import "./home-page-sell-item.scss";
import { Link } from "react-router-dom";
import { ItemMaster } from "../../model/pos/item-master";

export type HomePageSellItemProps = {
  item: ItemMaster;
};

/**
 * A component that behaves and is styled like a link,
 * but in reality goes nowhere
 */
const HomePageSellItem = ({ item }: HomePageSellItemProps) => {
  return (
    <EuiFlexGroup
      className="home-page-sell-item"
      direction="column"
      alignItems="center"
    >
      <Link to={`/web/detail/${item.uid}`}>
        <EuiFlexItem className="top">
          <EuiText size="m" grow={true} style={{ flexGrow: 1 }}>
            {item.description}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiImage src={item.imageUrl} alt="img alternate" />
        </EuiFlexItem>
        <EuiFlexItem className="bottom">
          <EuiText size="s">{item.name}</EuiText>
        </EuiFlexItem>
      </Link>
    </EuiFlexGroup>
  );
};

export default HomePageSellItem;
