import "./survey-warning.scss";

const SurveyWarning = () => {
  return (
    <div className="survey-warning-container">
      <div className="header">Cialis&reg; OTC is not for Everyone.</div>
      <p className="sub-header">Cialis&reg; OTC:</p>
      <div className="item-bullet">
        <div className="bullet" />
        Will NOT work if you do not have ED
      </div>
      <div className="item-bullet">
        <div className="bullet" />
        Will NOT increase the size of your penis
      </div>
      <div className="item-bullet">
        <div className="bullet" />
        Will NOT help with premature ejaculation
      </div>
      <div className="item-bullet">
        <div className="bullet" />
        Is NOT for women
      </div>
      <p className="paragraph">
        Cialis&reg; OTC is also not for men with certain health conditions or
        taking certain medications. This survey will check for those conditions
        and medications.
      </p>
      <p className="paragraph bold">
        By clicking "Continue" I confirm that I will answer the survey
        truthfully. Otherwise, it could lead to serious health consequences
        including blindness and heart attack.
      </p>
    </div>
  );
};

export default SurveyWarning;
