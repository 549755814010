import { useEffect, useState } from "react";
import "./survey-question.scss";
import { Question } from "../../../model/sanofi-otc/question";

/**
 * The props needed to build the question
 * @prop {Question} question - The question to render
 * @prop {(string) => void} answer - What the customer answered
 */
export type SurveyQuestionProps = {
  question: Question;
  answer: (answer: string) => void;
};

/**
 * This component returns the formatted question passed into it
 * @returns {JSX.Element}
 */
const SurveyQuestion = ({ question, answer }: SurveyQuestionProps) => {
  // The local yes or no for style purposes
  const [localAnswer, setLocalAnswer] = useState<string>("");

  // Fire the callback when an answer is selected
  useEffect(() => {
    if (!localAnswer) return;

    answer(localAnswer);
  }, [answer, localAnswer]);

  // Reset the local answer when the question changes
  useEffect(() => {
    setLocalAnswer("");
  }, [question]);

  return (
    <div className="survey-question-container">
      <div className="header">{question.header}</div>
      <p className="paragraph">{question.details}</p>
      <div className="button-container">
        <div
          className={`button ${localAnswer === "yes" && "selected"}`}
          onClick={() => setLocalAnswer("yes")}
        >
          Yes
        </div>
        <div
          className={`button ${localAnswer === "no" && "selected"}`}
          onClick={() => setLocalAnswer("no")}
        >
          No
        </div>
      </div>
    </div>
  );
};

export default SurveyQuestion;
