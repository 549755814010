import CostcoLogo from "./costco-logo.svg";
import ShoppingCartLogoBlue from "./cart-blue.svg";
import ShoppingCartLogoWhite from "./cart-white.svg";
import BarCode from "./bar-code.svg";
import HomePageShoppingBanner1 from "./costco-homepage/top.avif";
import HomePageShoppingBanner2 from "./kroger-homepage/pizza.webp";
import StopSign from "./stopsign.jpg";
import KrogerLogo from "./kroger-logo-white.svg";
import KrogerLogoBlue from "./kroger-logo-blue.svg";
import PickupLogo from "./pickup.svg";

export {
  CostcoLogo,
  BarCode,
  HomePageShoppingBanner1,
  HomePageShoppingBanner2,
  ShoppingCartLogoBlue,
  ShoppingCartLogoWhite,
  StopSign,
  KrogerLogo,
  KrogerLogoBlue,
  PickupLogo,
};
