import "./survey-welcome.scss";

/**
 * The welcome view
 * @returns {JSX.Element}
 */
const SurveyWelcome = () => {
  return (
    <div className="survey-welcome-container">
      <div className="header">Welcome!</div>
      <div className="paragraph">
        This survey will determine if Cialis&reg; OTC is appropriate for you to
        use without a doctor's prescription. You will receive 1 of 3 outcomes
        based on your health history:
      </div>
      <div className="item-bullet">
        <div className="bullet" />
        You may receive a purchase code that lets you buy Cialis&reg; OTC. This
        code will be valid for a limited time.
      </div>
      <div className="item-bullet">
        <div className="bullet" />
        You may be told that you need to talk to a doctor or pharmacist BEFORE
        you can buy Cialis&reg; OTC.
      </div>
      <div className="item-bullet">
        <div className="bullet" />
        You may be told that it is not safe for you to use Cialis&reg; OTC.
      </div>
    </div>
  );
};

export default SurveyWelcome;
