import { useEffect, useMemo, useState } from "react";
import { ShoppingCartService } from "../../../services/shopping-cart-service";
import "./website-cart-page.scss";
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import FakeLink from "../../../components/fake-link/fake-link";
import CartPageItem from "../../../components/cart-page-item/cart-page-item";
import { ItemMaster } from "../../../model/pos/item-master";
import HomePageItemRow from "../../../components/home-page-item-row/home-page-item-row";
import { Location } from "../../../services/geoapify-service";
import { Insertable, UidKey } from "../../../model/common";
import { Receipt } from "../../../model/pos/receipt";
import { CrudResponse } from "../../../qubes-client/protocol/crud.protocol";
import { QubesService } from "../../../services/qubes-service";
import { Register } from "../../../model/pos/register";
import { ReceiptLineItem } from "../../../model/pos/receipt-line-item";
import { useNavigate } from "react-router-dom";

export type WebsiteCartPageProps = {
  itemsDatabase: ItemMaster[] | undefined;
  customerLocation: Location | undefined;
  register: Register | undefined;
};

/**
 * This is the page that controls all actions within the POS demo track and
 * renders all the appropriate child components
 * @returns {JSX.Element}
 */
const WebsiteCartPage = ({
  itemsDatabase,
  customerLocation,
  register,
}: WebsiteCartPageProps): JSX.Element => {
  const navigate = useNavigate();

  const [shoppingCartItems, setShoppingCartItems] = useState<
    [string, number][]
  >([]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    const sub = ShoppingCartService.get()
      .observable()
      .subscribe((next: Map<string, number>) => {
        setShoppingCartItems(Array.from(next));
      });

    return () => sub.unsubscribe();
  }, [setShoppingCartItems]);

  const totalItemCount = useMemo(() => {
    let count = 0;
    shoppingCartItems.forEach((entry) => {
      count += entry[1];
    });
    return count;
  }, [shoppingCartItems]);

  const subtotal = useMemo(() => {
    return shoppingCartItems.reduce((counter, entry) => {
      const resolvedItem = itemsDatabase?.find((item) => item.uid === entry[0]);
      if (!resolvedItem) return counter;
      counter += entry[1] * resolvedItem.price;
      return counter;
    }, 0);
  }, [shoppingCartItems, itemsDatabase]);

  const checkout = async () => {
    try {
      const receipt: Insertable<Receipt> = {
        registerUid: register!.uid,
        active: true,
      };

      console.info("Insert Receipt");
      const receiptInsertResult: CrudResponse<UidKey> =
        await QubesService.insertReceipt(receipt);

      if (receiptInsertResult.success && receiptInsertResult.keys != null) {
        // Create each of the transaction items
        const lineItems = await Promise.all(
          shoppingCartItems.map(async (cartItem) => {
            const item: ItemMaster | undefined = itemsDatabase?.find(
              (item) => item.uid === cartItem[0]
            );
            if (item) {
              const rli: ReceiptLineItem = ReceiptLineItem.fromItem(item);
              console.log(`Insert ReceiptLineItem | ItemUid: ${rli.itemUid}`);
              rli.receiptUid = receiptInsertResult.keys?.uid;
              rli.quantity = cartItem[1];

              if (item.sanofiOtcSku) {
                const posEventUid = ShoppingCartService.get()
                  .getItemToEventMap()
                  .get(item.uid);

                if (!posEventUid) {
                  console.error(
                    "Sanofi item purchased but no posEventUid found"
                  );
                } else {
                  try {
                    const posEventResult = await QubesService.loadPosEvent(
                      posEventUid
                    );

                    if (posEventResult.data && posEventResult.data[0]) {
                      const posEvent = posEventResult.data[0];
                      console.info(
                        `Update the POSEvent records to "Sold" | ${item?.name}`
                      );
                      posEvent.upc = item?.upc;
                      posEvent.quantity = 1;
                      posEvent.status = "Sold";
                      posEvent.saleCompleted = new Date().toISOString();
                      await QubesService.updatePOSEvent(posEvent);
                    } else {
                      console.error(`POSEvent (${posEventUid}) not found!`);
                    }
                  } catch (err) {
                    console.error("Error update the pos events", err);
                  }
                }
              }

              return QubesService.insertReceiptLineItem(rli);
            }
          })
        );
        console.info(
          `All inserts successful? | ${lineItems?.every((li) => li?.success)}`
        );
        //clear the cart
        ShoppingCartService.get().clear();
        navigate(`/web/receipt/${receiptInsertResult.keys?.uid ?? "13982932"}`);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <EuiFlexGroup
      className="website-cart-page"
      direction="column"
      alignItems="flexStart"
      justifyContent="flexStart"
      gutterSize="none"
    >
      <EuiSpacer size="l" />
      <EuiFlexItem className="cart-header" grow={false}>
        <EuiFlexGroup
          direction="row"
          justifyContent="flexStart"
          alignItems="baseline"
          gutterSize="m"
        >
          <EuiText className="cart-header-title">Cart</EuiText>
          <EuiText className="cart-header-count">
            ({totalItemCount} Items)
          </EuiText>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiSpacer size="l" />
      <EuiFlexGroup className="cart-two-column" direction="row">
        <EuiFlexGroup className="cart-left-column" direction="column">
          <EuiSpacer size="xs" />
          <EuiFlexItem grow={false}>
            <EuiFlexGroup
              className="cart-delivery-info"
              direction="column"
              gutterSize="s"
            >
              <EuiText className="delivery-info-title">2-Day Delivery</EuiText>
              <EuiText className="delivery-info-details">
                2-Day Delivery orders are delivered within 2 business days when
                ordered by 12 p.m.
              </EuiText>
              <FakeLink text="Delivery Details" />
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexGroup
            className="cart-item-info"
            direction="column"
            justifyContent="flexStart"
            gutterSize="none"
          >
            {shoppingCartItems.map((item) => {
              const resolvedItem = itemsDatabase?.find(
                (i) => i.uid === item[0]
              );
              if (!resolvedItem) return null;
              return (
                <CartPageItem
                  key={item[0]}
                  item={resolvedItem!}
                  quantity={item[1]}
                  onQuantityChange={(newQuantity) => {
                    ShoppingCartService.get().update(
                      resolvedItem!,
                      newQuantity
                    );
                    if (resolvedItem.sanofiOtcSku && newQuantity < 1) {
                      ShoppingCartService.get().removeItemToEvent(resolvedItem);
                    }
                  }}
                />
              );
            })}
          </EuiFlexGroup>
        </EuiFlexGroup>

        <EuiFlexGroup
          className="cart-right-column"
          direction="column"
          justifyContent="spaceEvenly"
          gutterSize="s"
        >
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiText>Subtotal</EuiText>
            <EuiText>${subtotal?.toFixed(2)}</EuiText>
          </EuiFlexGroup>

          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexGroup direction="column" gutterSize="none">
              <EuiText>Shipping & Handling for</EuiText>
              <FakeLink text={customerLocation?.postCode ?? ""} />
            </EuiFlexGroup>
            <EuiText>$0.00</EuiText>
          </EuiFlexGroup>

          <EuiHorizontalRule margin="s" />

          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiText>Estimated Total</EuiText>
            <EuiText>${subtotal?.toFixed(2)}</EuiText>
          </EuiFlexGroup>

          <EuiFlexItem grow={false}>
            <EuiText className="taxes-warning">
              Applicable taxes will be calculated at checkout.
            </EuiText>
          </EuiFlexItem>

          <EuiButton className="checkout-button" onClick={checkout}>
            Checkout
          </EuiButton>
        </EuiFlexGroup>
      </EuiFlexGroup>
      <EuiHorizontalRule margin="l" />
      <EuiText className="recently-viewed-products-label">
        Recently Viewed Products
      </EuiText>
      <EuiSpacer size="m" />
      <HomePageItemRow items={itemsDatabase?.slice(0, 5) ?? []} />
    </EuiFlexGroup>
  );
};

export default WebsiteCartPage;
